<template>
  <div class="">
    <div class="table-responsive text-nowrap">
      <md-table
        :columns="columns"
        :data-source="data"
        size="small"
        row-key="id"
        :pagination="false"
      >
        <template #action>
          <span>
            <a-tooltip title="Ubah Role">
              <a href="javascript: void(0);" class="btn btn-sm btn-light">
                <small>
                  <i class="fe fe-settings" />
                </small>
              </a>
            </a-tooltip>
          </span>
        </template>
      </md-table>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import adata from './data.json'
const acolumns = [
  {
    title: 'Nama Dokumen',
    dataIndex: 'nama_dokumen',
  },

  {
    title: 'Action',
    slots: { customRender: 'action' },
  },
]

export default {
  setup() {
    const searchText = ref('')
    const searchInput = ref(null)
    const columns = ref([])
    const data = ref({})
    const current1 = ref(1)
    data.value = adata.slice('')
    columns.value = acolumns

    const handleSearch = (selectedKeys, confirm) => {
      confirm()
      searchText.value = selectedKeys[0]
    }

    const handleReset = clearFilters => {
      clearFilters()
      searchText.value = ''
    }

    return {
      searchText,
      searchInput,
      data,
      columns,
      handleReset,
      handleSearch,
      current1,
    }
  },
}
</script>

<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
