<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <div class="card-body">
            <!-- TODO: Komponen Filter Tabel -->
            <m-table />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MTable from './components/forms/table' // local component

export default {
  name: 'VbSyaratDanKetentuan',
  components: {
    MTable,
  },
}
</script>